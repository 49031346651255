import $ from "jquery";
import { gsap } from "gsap";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";

const { default: Swiper } = await import("swiper");
const { Pagination, EffectFade } = await import("swiper/modules");

const initializeMainSlider = () => {
  $(".product__photos .js-slider-main").each(function () {
    const swiperOptions = {
      modules: [EffectFade, Pagination],
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };

    $(this).data("swiperInstance", new Swiper(this, swiperOptions));
  });
};

const destroySliders = () => {
  $(".product__photos .js-slider-main").each(function () {
    const swiperInstance = $(this).data("swiperInstance");
    if (swiperInstance) swiperInstance.destroy(true, true);
  });
};

const mm = gsap.matchMedia();

mm.add("(max-width: 1279px)", () => {
  initializeMainSlider();

  return () => {
    destroySliders();
  };
});
